<template>
  <div class="container-height">
    <p>All Competitors</p>
  </div>
</template>

<script>
export default {
  name: 'AllCompetitors',
}
</script>

<style scoped lang="scss">
.container-height {
  min-height: calc(100vh - 200px);
}
</style>
